import React from 'react';
import { ParagraphBaseHeavy } from 'components/Typography';
import { ScheduleClosureForm } from 'components/ScheduleClosureForm';
import { ScheduledClosuresTable } from 'components/ScheduledClosuresTable';

export const ScheduledClosures = () => (
  <>
    <ParagraphBaseHeavy as="h2">Scheduled closures</ParagraphBaseHeavy>
    <ScheduleClosureForm />
    <ScheduledClosuresTable />
  </>
);
