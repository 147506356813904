import request from 'superagent';
import { STRINGS } from 'config';

const {
  API: { CUSTOMISATION_URL },
  FEATURE_GROUPS: { SETTINGS_FRONTEND_FEATURE_FLAGS },
  REDUCERS: {
    FEATURE_FLAGS: {
      GET_FEATURE_FLAGS_REQUEST,
      GET_FEATURE_FLAGS_SUCCESS,
      GET_FEATURE_FLAGS_ERROR,
    },
  },
} = STRINGS;

export const getFeatureFlags = practiceId => dispatch => {
  dispatch({ type: GET_FEATURE_FLAGS_REQUEST });
  return request
    .get(`${CUSTOMISATION_URL}/group/${SETTINGS_FRONTEND_FEATURE_FLAGS}`)
    .set({ 'X-OWNER-ID': practiceId })
    .then(response => dispatch({ type: GET_FEATURE_FLAGS_SUCCESS, payload: response.body.values }))
    .catch(error => dispatch({ type: GET_FEATURE_FLAGS_ERROR, payload: error }));
};
