import React, { useState } from 'react';
import { isAfter } from 'date-fns/isAfter';
import { add } from 'date-fns/add';
import { Datepicker, Timepicker } from '@econsult/econsult-ui/dist/components/Datepicker';
import { Button } from '@econsult/econsult-ui/dist/components/Button';
import { useAppDispatch, useAppState } from 'store/configureStore';
import { getScheduledClosures, saveScheduledClosure } from 'store/action/demandManagement';
import { CustomAnchoredToolTip } from 'components/Table';
import { Legend, FieldSet } from 'components/Form';
import { Form, InputLabel, InputRow, Notification } from './style';

const getClosureDateTimesFromForm = formElements => {
  const { startDate, startTime, endDate, endTime } = formElements;
  return [new Date(`${startDate.value}T${startTime.value}`), new Date(`${endDate.value}T${endTime.value}`)];
};

const initialStartTimeState = '00:00';
const initialEndTimeState = '23:59';

export const ScheduleClosureForm = () => {
  const dispatch = useAppDispatch();
  const {
    app: {
      selectedPractice,
      selectedPracticeHasAdminRights,
    },
    demandManagement: {
      demandManagementEnabled,
    },
  } = useAppState();

  const [startTime, setStartTime] = useState(initialStartTimeState);
  const [endTime, setEndTime] = useState(initialEndTimeState);
  const [validation, setValidation] = useState(null);

  const resetForm = form => {
    form.reset();
    setStartTime(initialStartTimeState);
    setEndTime(initialEndTimeState);
  };

  const onSaveScheduledClosure = event => {
    event.preventDefault();
    const [startDateTime, endDateTime] = getClosureDateTimesFromForm(event.target.elements);
    if (!isAfter(endDateTime, startDateTime)) {
      return setValidation('Please ensure the scheduled closure start date is before the end date.');
    }
    if (isAfter(endDateTime, add(startDateTime, { days: 7 }))) {
      return setValidation('Scheduled closure must not be longer than 7 days.');
    }
    if (isAfter(new Date(), endDateTime)) {
      return setValidation('The end of the closure must be in the future.');
    }
    setValidation(null);
    const closure = {
      startDateTime: startDateTime.toISOString(),
      endDateTime: endDateTime.toISOString(),
    };
    return saveScheduledClosure(selectedPractice.id, closure)(dispatch)
      .then(isSuccess => {
        if (isSuccess) {
          resetForm(event.target);
          getScheduledClosures(selectedPractice.id)(dispatch);
        }
      });
  };

  return (
    <Form action="" onSubmit={onSaveScheduledClosure}>
      {validation && <Notification state="error" textContent={validation} variant="filled" automationName="scheduledClosuresValidation" />}
      <FieldSet>
        <Legend>
          Schedule a closure of your eConsult service for up to five working days.
          When closed, eConsult lite will remain available and patients will still be able to reply to two-way messages.
          If your practice has enabled long-term condition reviews to be completed outside of your eConsult hours,
          these can also still be completed by a patient if they have been sent a direct link.
        </Legend>
        <InputRow>
          <InputLabel>
            Start date
            <Datepicker name="startDate" max="9999-12-31" />
          </InputLabel>
          <InputLabel>
            Start time
            <Timepicker value={startTime} onChange={event => setStartTime(event.target.value)} name="startTime" />
          </InputLabel>
          <InputLabel>
            End date
            <Datepicker name="endDate" max="9999-12-31" />
          </InputLabel>
          <InputLabel>
            End time
            <Timepicker value={endTime} onChange={event => setEndTime(event.target.value)} name="endTime" />
          </InputLabel>
          <Button
            disabled={!selectedPracticeHasAdminRights || !demandManagementEnabled}
            type="submit"
            data-automation="SaveScheduledClosureButton"
            size="small"
          >
            Schedule
            {!selectedPracticeHasAdminRights && (
              <CustomAnchoredToolTip
                automationLabel="SaveDemandManagementDisabledTooltip"
                label="Contact your practice admin"
                align="top"
              />
            )}
          </Button>
        </InputRow>
      </FieldSet>
    </Form>
  );
};
