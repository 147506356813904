import React, { useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { useAppDispatch, useAppState } from 'store/configureStore';
import { getScheduledClosures } from 'store/action/demandManagement';
import { ParagraphXSmallHeavy, ParagraphXSmallRegular } from 'components/Typography';
import { StyledTableRow } from 'components/Table';
import { HeaderCell, DataCell } from './style';

const formatDateTime = dateTime => format(parseISO(dateTime), 'dd/MM/yyyy (HH:mm)');

export const ScheduledClosuresTable = () => {
  const dispatch = useAppDispatch();
  const {
    app: {
      selectedPractice,
      selectedPracticeIsEhub,
    },
    demandManagement: {
      scheduledClosures,
    },
  } = useAppState();

  useEffect(() => {
    if (!selectedPracticeIsEhub) {
      getScheduledClosures(selectedPractice.id)(dispatch);
    }
  }, [dispatch, selectedPracticeIsEhub, selectedPractice.id]);

  return (
    <>
      <ParagraphXSmallHeavy as="h3">Active and future closures</ParagraphXSmallHeavy>
      {_isEmpty(scheduledClosures) ? (
        <ParagraphXSmallRegular data-automation="NoClosures">No closures currently scheduled.</ParagraphXSmallRegular>
      ) : (
        <Table aria-label="scheduled-closures-table">
          <TableHead>
            <TableRow data-automation="TableHeaderRow">
              <HeaderCell data-automation="TableHeaderStartDateTime">Start date & time</HeaderCell>
              <HeaderCell data-automation="TableHeaderEndDateTime">End date & time</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_map(scheduledClosures, closure => (
              <StyledTableRow data-automation="TableRow" key={closure.id}>
                <DataCell scope="row" data-automation="TableDataStartDateTime">
                  {formatDateTime(closure.startDateTime)}
                </DataCell>
                <DataCell scope="row" data-automation="TableDataEndDateTime">
                  {formatDateTime(closure.endDateTime)}
                </DataCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
