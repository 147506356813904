import React from 'react';
import { MoreInfoWrapper } from 'components/DemandManagement/style';
import { ParagraphXSmallRegular, ParagraphSmallHeavy } from 'components/Typography';
import { ExternalLink } from 'components/ExternalLink';
import { STRINGS } from 'config';

const { DEMAND_MANAGEMENT_HELP_URL } = STRINGS;

export const MoreInfo = ({ demandManagementEnabled, demandManagementSettingUrl }) => (
  <MoreInfoWrapper>
    <ParagraphSmallHeavy as="h3">More about demand management</ParagraphSmallHeavy>
    {demandManagementEnabled && (
      <ParagraphXSmallRegular>
        Make changes to other&nbsp;
        <ExternalLink url={demandManagementSettingUrl} label="demand management settings." automationName="DemandManagementWebapp" />
      </ParagraphXSmallRegular>
    )}
    <ParagraphXSmallRegular>
      Find out more about our demand management functionality in our&nbsp;
      <ExternalLink url={DEMAND_MANAGEMENT_HELP_URL} label="help centre." automationName="DemandManagementHelpCentre" />
    </ParagraphXSmallRegular>
  </MoreInfoWrapper>
);
